<template>
    <div>
        <cloud-panel
            v-if="localTelemetry && localTelemetry.length > 0"
            :ignore-null-value="true"
            :title="__('telemetry')"
            :data="telemetryInfo"
            :searchable="true"
            @search="filterTelemetries"
        />
    </div>
</template>

<script>
import CloudPanel from './CloudPanel.vue'

export default {
    name: 'TelemetryCloudPanel',
    props: {
        truck: {
            type: Object,
            required: true
        }
    },
    components: {
        CloudPanel
    },
    data() {
        return {
            localTelemetry: null,
            filteredTelemetries: null,
            loading: false
        }
    },
    created() {
        this.getTelemetries()

        // refresh telemetries every 1 minutes
        setInterval(() => {
            this.getTelemetries()
        }, 60000)
    },
    methods: {
        getTelemetries() {
            this.loading = true

            this.$fetch(this.$env.GOLIA_API + '/geofleet/trucks/' + this.truck.id + '/telemetry')
                .then((response) => {
                    return response.json()
                })
                .then((response) => {
                    this.loading = false

                    this.localTelemetry = response?.data?.filter(
                        (t) => t.category != 'Tachograph_Data'
                    )
                    this.filteredTelemetries = this.localTelemetry

                    this.$emit('telemetriesLoaded', this.localTelemetry)
                })
                .catch((error) => {
                    this.loading = false
                    console.log(error)
                })
        },
        filterTelemetries(value) {
            if (value.length > 0)
                this.filteredTelemetries = this.localTelemetry.filter((t) => {
                    let label = t.config?.label || t.name
                    label = this.__(label)

                    return label.toLowerCase().includes(value)
                })
            else this.filteredTelemetries = this.localTelemetry
        }
    },
    computed: {
        telemetryInfo() {
            if (!this.filteredTelemetries) return {}

            const excludedTelemetryNames = [
                'Speed',
                'Fuel Level',
                'Engine RPM',
                'Data Mode',
                'Battery Current',
                'PCB Temperature',
                'Wheel Based Speed',
                'Tachograph Performance',
                'Direction Indication',
                'Handling Info',
                'Movement',
                'System Event',
                'Trip Odometer',
                'Axis X',
                'Axis Z',
                'Axis Y',
                'GNSS PDOP',
                'GNSS HDOP',
                'GSM Area Code',
                'GSM Cell ID',
                'Ignition',
                'PTO State',
                'Program Number',
                'Door Status'
            ]

            return this.filteredTelemetries
                .filter((t) => !excludedTelemetryNames.includes(t.name))
                .reduce((c, t) => {
                    var label = t.config?.label || t.name

                    // slugify label
                    // label = label.replace(/[^a-zA-Z0-9]/g, '_').toLowerCase();

                    if (t.value?.parsed !== null) {
                        let value = t.value?.parsed

                        // If t.value.parsed is boolean set value to this.__('yes') or this.__('no')
                        if (typeof value === 'boolean') value = this.__(value ? 'yes' : 'no')
                        // If t.value.parsed is number and t.value.um is defined set value to toLocaleString
                        else if (typeof value === 'number' && t.value?.um)
                            value = new Intl.NumberFormat().format(Math.trunc(value))
                        // If t.cast contain 'C.0' we can assume that the string can be translated
                        else if (t.cast?.includes('C:')) {
                            value = this.__(value)
                        }

                        c[label] = { value: value, description: t.description, um: t.value?.um }
                    }

                    return c
                }, {})
        }
    },
    watch: {
        truck() {
            this.getTelemetries()
        }
    }
}
</script>
